
import { Vue, Component } from 'vue-property-decorator'
import { Header, Footer } from '@/components'
import Login from '@/components/login/loginIndex.vue'

@Component({
  components: {
    Header,
    Footer,
    Login
  }
})
export default class Home extends Vue {}
