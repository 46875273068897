import Vue from 'vue'
const inputintonly = Vue.directive('inputintonly', {
  update: (el) => {
    const input =
      el.nodeName.toLowerCase() === 'input'
        ? (el as HTMLInputElement)
        : el.getElementsByTagName('input')[0]
    if (input) {
      // 改变值过后，需要主动触发input时间，组件绑定的值才会改变
      const ev = new Event('input', { bubbles: true })
      const val = input.value.replace(/[^0-9]/gi, '')
      if (input.value === val) return

      input.value = val
      input.dispatchEvent(ev)
    }
  }
})
export default inputintonly
