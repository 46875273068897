
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import OssUpload from '@/components/oss-upload/single.vue'
import { CommonService } from '@/api/common'
import moment from 'moment'
import globalSetting from '@/utils/globalSetting'

@Component({
  components: {
    OssUpload
  }
})
export default class FeedBack extends Vue {
  @Prop({ required: true })
  private show!: boolean

  @Prop({ default: '' })
  private title!: string

  public moment = moment
  private interfaceImgPath = ''
  private imgList: any[] = []
  private tagList: any[] = []
  private modalForm: any = {
    type: '',
    content: '',
    imgUrl: '',
    deviceSource: 12
  }
  private recordList: any[] = []

  protected async mounted () {
    const config: any = await globalSetting.getSetting()
    this.interfaceImgPath = config.urlList.imgUrl // 图片前缀

    CommonService.getDic('feedbackType').then((res) => {
      this.tagList = res.result
    })
  }

  private login () {
    this.close()
    this.$store.dispatch('setLoginVisible', true)
  }

  private typeClick (item: any) {
    this.modalForm.type = item.code
  }

  private submit () {
    if (!this.modalForm.type) {
      return this.$Message.error('请选择问题类型')
    }
    if (!this.modalForm.content) {
      return this.$Message.error('请根据您选择的问题类型输入描述')
    }

    this.modalForm.imgUrl =
      this.imgList && this.imgList.length ? this.imgList.join(',') : ''

    CommonService.feedbackAdd(this.modalForm).then(() => {
      this.$Message.success('反馈成功')
      this.close()
    })
  }

  private recordClick () {
    CommonService.feedbackList({ pn: 1, ps: 1000 }).then((res) => {
      this.recordList = res.result.list
    })
    this.$emit('changeTitle', '反馈记录')
  }

  private close () {
    this.$emit('close')
    this.$emit('changeTitle', '')
  }

  private getImg (img: string) {
    return img ? img.split(',') : []
  }
}
