
import { Message } from 'view-design'
// eslint-disable-next-line import/no-named-as-default
import md5 from 'js-md5'
import { defineComponent, reactive, toRefs, watch } from 'vue'
// eslint-disable-next-line import/no-named-as-default
import store from '@/store'
import { isMobilePhone } from '@hxq/utils'
import { ClinicAPI } from '@/api'

export default defineComponent({
  components: {},
  setup (_props, { emit }) {
    const codeLoginParams = reactive({
      mobile: '',
      code: '',
      account: '',
      password: '',
      isRead: false
    })
    const state = reactive({
      activeTab: 0,
      codeErrorMsg: '',
      passwordErrorMsg: '',
      captchaText: '获取验证码',
      isSubmit: false,
      time: 60,
      captchaDisabled: false
    })
    watch(codeLoginParams, () => {
      if (
        codeLoginParams.isRead &&
        (state.codeErrorMsg === '请阅读并同意协议' ||
          state.passwordErrorMsg === '请阅读并同意协议')
      ) {
        state.codeErrorMsg = ''
        state.passwordErrorMsg = ''
      }
      if (state.activeTab === 0) {
        if (
          codeLoginParams.mobile.length === 11 &&
          codeLoginParams.code.length === 6
        ) {
          state.isSubmit = true
        }
        else state.isSubmit = false
        state.codeErrorMsg = ''
      } else {
        state.passwordErrorMsg = ''
      }
    })
    const getCode = () => {
      if (state.captchaDisabled || codeLoginParams.mobile.length === 0) return
      if (!isMobilePhone(codeLoginParams.mobile)) {
        return (state.codeErrorMsg = '请输入正确的手机号')
      }
      ClinicAPI.getCaptcha(codeLoginParams.mobile).then(() => {
        countDown()
      })
    }
    const countDown = () => {
      if (state.time > 0) {
        state.time--
        state.captchaText = `${state.time}s后重新获取`
        state.captchaDisabled = true
        setTimeout(() => {
          countDown()
        }, 1000)
      } else {
        state.time = 60
        state.captchaText = '获取验证码'
        state.captchaDisabled = false
      }
    }
    const handleLogin = () => {
      // 验证码登录
      if (state.activeTab === 0) {
        if (!state.isSubmit) return
        if (!codeLoginParams.isRead) {
          return (state.codeErrorMsg = '请阅读并同意协议')
        }
        state.codeErrorMsg = ''
        ClinicAPI.login(codeLoginParams.mobile, codeLoginParams.code).then(
          (res) => {
            // 新用户-需要完善信息
            if (res.result.isNew === 0) {
              emit('step', 2)
            } else {
              store.dispatch('setLoginVisible', false);
              (Message as any).success('登录成功')
              format()
            }
            store.dispatch('setUserInfo', res.result)
          }
        )
      }
      // 账号登录
      if (state.activeTab === 1) {
        // if (!isMobilePhone(codeLoginParams.account))
        //   return (state.passwordErrorMsg = '请输入正确的手机号')
        if (!codeLoginParams.password) {
          return (state.passwordErrorMsg = '请输入密码')
        }
        if (!codeLoginParams.isRead) {
          return (state.passwordErrorMsg = '请阅读并同意协议')
        }
        state.passwordErrorMsg = ''
        ClinicAPI.accountLogin(
          codeLoginParams.account,
          md5(codeLoginParams.password)
        ).then((res) => {
          store.dispatch('setLoginVisible', false);
          (Message as any).success('登录成功')
          format()
          store.dispatch('setUserInfo', res.result)
        })
      }
    }
    const format = () => {
      codeLoginParams.mobile = ''
      codeLoginParams.isRead = false
      codeLoginParams.code = ''
      codeLoginParams.password = ''
      codeLoginParams.account = ''
    }
    return {
      ...toRefs(state),
      ...toRefs(codeLoginParams),
      handleLogin,
      getCode
    }
  }
})
