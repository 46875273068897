import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import { sync } from 'vuex-router-sync'
import App from './App.vue'
import Swiper from 'swiper'
import iView from 'view-design'
import VueLazyload from 'vue-lazyload'
// import 'view-design/dist/styles/iview.css'
import '@/filters'
import 'swiper/css/swiper.css'
import 'animate.css'
import '@/style/iview.less'
import Inputintonly from './directive/inputintonly'

Vue.use(iView)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})
Vue.directive('inputintonly', Inputintonly)
Vue.config.productionTip = false
sync(store, router)
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
