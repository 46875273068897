
import { defineComponent, reactive, toRefs } from 'vue'
import LoginRight from './components/loginRight.vue'
import LoginLeft from './components/loginLeft.vue'
import PerfectMsg from './components/perfectMsg.vue'
import ForgetPwd from './components/forgetPwd.vue'
import ResetPwd from './components/resetPwd.vue'
// eslint-disable-next-line import/no-named-as-default
import store from '@/store'

export default defineComponent({
  components: { LoginRight, LoginLeft, PerfectMsg, ForgetPwd, ResetPwd },
  props: {
    showClose: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const state = reactive({
      step: 1 // 1.登录注册   2.完善信息   3.找回密码  4.重置密码
    })
    const stepChange = (val: number) => {
      state.step = val
    }
    const closeModel = () => {
      store.dispatch('setLoginVisible', false)
      stepChange(1)
    }
    return {
      ...toRefs(state),
      stepChange,
      closeModel
    }
  }
})
