
import { LocalStore } from '@hxq/utils'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { isEmpty } from '@/utils/util'

@Component({
  components: {},
  name: 'App'
})
export default class App extends Vue {
  @Watch('$route')
  private UWebChange () {
    const _czc = (window as any)._czc || []
    if (_czc) {
      const contentUrl = this.$route.path
      const refererUrl = location.origin + sessionStorage.getItem('preRoutePath')
      _czc.push(['_trackPageview', contentUrl, refererUrl])
    }
  }
}
