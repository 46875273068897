
import { defineComponent, reactive, toRefs } from 'vue'
import { Message } from 'view-design'
import moment from 'moment'
// eslint-disable-next-line import/no-named-as-default
import store from '@/store'
import { ClinicAPI } from '@/api'

export default defineComponent({
  components: {},
  setup (_props, { emit }) {
    const state = reactive({
      name: '',
      birthday: '',
      sex: ''
    })
    const jump = () => {
      (Message as any).success('登录成功')
      store.dispatch('setLoginVisible', false)
    }
    const submit = () => {
      const birthday = state.birthday
        ? moment(state.birthday).format('YYYY-MM-DD')
        : ''
      ClinicAPI.perfectMsg(state.name, birthday, state.sex).then(() => {
        const userInfo = {
          ...store.getters.getUserInfo,
          name: state.name
        }
        store.dispatch('setUserInfo', userInfo);
        (Message as any).success('登录成功')
        store.dispatch('setLoginVisible', false)
        emit('step', 1)
      })
    }
    return {
      ...toRefs(state),
      submit,
      jump
    }
  }
})
