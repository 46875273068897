import { LocalStore } from '@hxq/utils'
import store from '@/store'
import http from '@/api/http'

class GlobalSetting {
  /**
   * 本地存储key
   */
  private STORE_KEY = 'HXQ_WWW_GLOBALCONFIG'
  /**
   * 获取远程配置
   */
  public getSetting () {
    return new Promise<any>((resolve, reject) => {
      const config = LocalStore.get(this.STORE_KEY)
      if (config) {
        resolve(config)
      } else {
        http.request({
          method: 'get',
          url: process.env.VUE_APP_P_API + '/papi/common/config',
          params: {
            systemIdentifier: process.env.VUE_APP_SYSTEMIDENTIFIER,
            env: process.env.VUE_APP_ENV
          }
        }).then((response: any) => {
          store.dispatch('setGlobalConfig', response.result)
          LocalStore.set(this.STORE_KEY, response.result)
          resolve(response.result)
        }).catch((error: any) => {
          reject(error)
        })
      }
    })
  }
}
export const globalSetting = new GlobalSetting()
export default globalSetting
