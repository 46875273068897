import http from '@/api/http'

export const ClinicAPI = {
  /** 获取验证码 */
  getCaptcha (mobile: string) {
    return http.post('/papi/smsRecord/getCaptchaWeb', {
      smsTempType: 'appPatientPwd',
      mobile
    })
  },
  // 用户注册
  registerUser (name: string, sex: number, age: string) {
    return http.post('/papi/user/mod', { name, sex, age })
  },
  // 手机号 验证码 登录 注册
  login (mobile: string, captcha: string) {
    return http.post('/papi/user/loginByCaptcha', { mobile, captcha })
  },
  // 用户端退出
  logout () {
    return http.post('/papi/user/logout')
  },
  getHospitalClinic () {
    return http.get('/papi/hospitalClinic/get')
  },
  // /papi/hospitalClinic/get
  /**
   * 医生列表
   * hospitaId:诊所id[long]
   * condition:条件[string]
   * tag[string]
   */
  getClinicDoctorList (params: object) {
    return http.post('/papi/clinicDoctor/list', params)
  },
  /** 医生详情 */
  getClinicDoctorDetail (doctorId: string) {
    return http.get('/papi/clinicDoctor/get', { doctorId })
  },
  /** 医生可约时间 */
  getDoctorScheduling (doctorId: string) {
    return http.get('/papi/doctorScheduling/get', { doctorId })
  },
  /** 就诊人列表 */
  getPatientList (doctorId: string) {
    return http.get('/papi/patient/list', { doctorId, completionStatus: '2' })
  },
  /** 就诊人add */
  addPatient (params: object) {
    return http.get('/papi/patient/add', params)
  },
  /** 就诊人mod */
  modPatient (params: object) {
    return http.get('/papi/patient/mod', params)
  },
  /** 就诊人信息 */
  getPatient (id: string) {
    return http.get('/papi/patient/get', { id })
  },
  /** 创建预约订单 */
  createOrder (params: object) {
    return http.get('/papi/serviceOrderOutpatient/add', params)
  },
  /** 订单详情 */
  getOrder (orderNo: number) {
    return http.get('/papi/serviceOrderOutpatient/get', { orderNo })
  },
  /** 获取验证码 */
  getResetCaptcha (mobile: string) {
    return http.post('/papi/smsRecord/getResetCaptcha', {
      smsTempType: 'appPatientPwd',
      mobile
    })
  },
  /** 验证验证码 */
  verifyCaptcha (params: object) {
    return http.post('/papi/user/checkResetByCaptcha', params)
  },
  // 密码登录
  accountLogin (mobile: string, password: string) {
    return http.post('/papi/user/login', { mobile, password })
  },
  // 完善信息
  perfectMsg (name: string, birthday: string, sex: any) {
    return http.post('/papi/user/mod', { name, birthday, sex })
  },
  /** 重置密码 */
  resetPassword (params: object) {
    return http.post('/papi/user/modPass', params)
  }
}
