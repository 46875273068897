import { LocalStore } from '@hxq/utils'
import Http from '@hxq/utils/lib/http'
import uuid from 'uuid'
import { Message } from 'view-design'
import { isEmpty, trim } from '@/utils/util'
import store from '@/store'
import md5 from 'js-md5'
const DID_KEY = 'HXQ_WWW_DID'

if (!LocalStore.get(DID_KEY)) {
  LocalStore.set(DID_KEY, uuid())
}

const http = new Http({
  baseURL: process.env.VUE_APP_P_API,
  cc: 'web',
  ver: '4.08',
  v: ' 20191231',
  deviceId: LocalStore.get(DID_KEY),
  timeout: 15000,
  commonParamsFn: () => {
    const operator: any = {}
    if (!isEmpty(store.getters.getUserInfo)) {
      operator.uuToken = store.getters.getUserInfo.uuToken
      operator.userId = store.getters.getUserInfo.userId
    }

    let params: any = {
      ...operator
    }

    params = trim(params)
    return params
  },
  successInterceptor: (res) => {
    if (res.code !== 1000) {
      (Message as any).error(res.msg)
      if (res.code === 4996 || res.code === 4997) {
        // LocalStore.clear()
        store.dispatch('setUserInfo', null)
        store.dispatch('setLoginVisible', true)
      }
      return Promise.reject(res)
    }
    return res
  },
  onError: (err) => {
    (Message as any).error(err.msg)
  },
  sign (params: Record<string, any>) {
    const keys = Object.keys(params)
    // 参数名排序
    keys.sort()

    // 参数拼接
    let str = keys.reduce((pre, cur, index) => {
      return (pre += `${index === 0 ? '' : '&'}${cur}=${
        params[cur] instanceof Object ? JSON.stringify(params[cur]) : params[cur]
      }`)
    }, '')

    // 从融云的key中取6-12位作为密码
    str += (str ? '&' : '') + `ufrfxa=${200 + keys.length}`
    return {
      key: 's',
      value: md5(str)
    }
  }
})
export default http
