
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Form, Message } from 'view-design'
import { ClinicAPI } from '@/api'
import { regExp } from '@/utils/validator'
import moment from 'moment'
import { isMobilePhone } from '@hxq/utils'
// isMobilePhone

@Component
export default class Login extends Vue {
  private step = 1
  public private = false
  private captchaDisabled = false
  private captchaText = '获取验证码'
  private time = 60
  private formData = {
    mobile: '',
    captcha: '',
    name: '',
    sex: 1,
    age: ''
  }
  @Watch('$route')
  @Watch('$store.getters.getUserInfo')
  private userInfoChange () {
    if (this.$route.name === 'pay' || this.$route.name === 'detail') {
      if (this.$store.getters.getUserInfo) {
        // 
      } else {
        this.$Modal.confirm({
          title: '是否退出登录，退出登录后将返回到首页',
          onOk: () => {
            ClinicAPI.logout().then((res) => {
              this.$store.dispatch('setUserInfo', null)
              this.$router.push('/')
            })
          },
          onCancel: () => {
            this.$store.dispatch('setUserInfo', null)
            this.$router.push('/lists')
          }
        })
      }
    }
  }
  private format () {
    this.formData = {
      mobile: '',
      captcha: '',
      name: '',
      sex: 1,
      age: ''
    }
    this.time = 0
  }
  private getCaptcha () {
    if (!isMobilePhone(this.formData.mobile)) {
      return (Message as any).error('请输入正确的手机号码')
    }
    ClinicAPI.getCaptcha(this.formData.mobile).then((res) => {
      this.countDown()
    })
  }
  private submit () {
    if (this.step === 1) {
      this.login()
    } else {
      this.registerUser()
    }
  }
  private registerValidate () {
    return new Promise((resolve, reject) => {
      if (!this.formData.name.length || !regExp.isNormalEncode.test(this.formData.name)) {
        reject('请输入姓名')
      }
      if (!this.formData.age) {
        reject('请选择您的年龄')
      }
      resolve()
    })
  }
  private onBirthConfirm (value: any) {
    this.formData.age = moment(value).format('YYYY-MM-DD')
  }
  private login () {
    ClinicAPI.login(this.formData.mobile, this.formData.captcha).then((res) => {
      if (res.result.isNew === 0) { // 新用户
        this.step = 2
      } else {
        if (res.result.name) {
          this.$store.dispatch('setLoginVisible', false)
        } else {
          this.step = 2
        }
        this.$Message.success('登录成功')
        this.format()
      }
      this.$store.dispatch('setUserInfo', res.result)
    })
  }
  private registerUser () {
    this.registerValidate().then(() => {
      ClinicAPI.registerUser(this.formData.name, +this.formData.sex, this.formData.age).then((res) => {
        const userInfo = {
          ...this.$store.getters.getUserInfo,
          name: this.formData.name
        }
        this.$store.dispatch('setUserInfo', userInfo)
        this.$Message.success('登录成功')
        this.$store.dispatch('setLoginVisible', false)
        this.format()
        this.step = 1
      })
    }).catch((err) => {
      (Message as any).error(err)
    })
  }
  private countDown () {
    if (this.time > 0) {
      this.time--
      this.captchaText = `重新获取 ${this.time}s`
      this.captchaDisabled = true
      setTimeout(() => {
        this.countDown()
      }, 1000)
    } else {
      this.time = 60
      this.captchaText = '获取验证码'
      this.captchaDisabled = false
    }
  }
}
