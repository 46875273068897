
import { defineComponent, getCurrentInstance } from 'vue'
// eslint-disable-next-line import/no-named-as-default
import store from '@/store'

export default defineComponent({
  components: {},
  setup () {
    const route = getCurrentInstance()?.proxy.$route!
    const router = getCurrentInstance()?.proxy.$router!
    const goHome = () => {
      store.dispatch('setLoginVisible', false)
      if (route.path !== '/') {
        router.push('/')
      }
    }
    return {
      goHome
    }
  }
})
