import Http from '@/api/http'
/**
 * 用作缓存字典
 * 从服务端拿到后，存储到这里；再读取的时候先从这里获取
 * 2019.12.12
 */
const dicCache: any = {}

export const CommonService = {
  // 获取stsToken
  getStsToken (
    params: object = {
      systemIdentifier: 'hxq',
      env: process.env.VUE_APP_ENV
    }
  ): any {
    return Http.request({
      url: '/papi/common/ossSignature',
      method: 'get',
      params
    })
  },
  /**
   * 获取字典列表
   * @param parentCode 字典类型
   */
  getDic (parentCode: string) {
    // 添加缓存处理。 2019.12.12
    if (dicCache[parentCode]) {
      return Promise.resolve({
        msg: '从本地获取成功',
        code: 1000,
        result: dicCache[parentCode]
      })
    } else {
      return Http.get('/papi/common/dictionaryList', { parentCode }).then(
        (res) => {
          dicCache[parentCode] = res.result
          return res
        }
      )
    }
  },
  /**
   * banner列表
   * @param position 位置： 1:医生端首页 2.用户端首页 3.精品专区 4.量表首页
   */
  bannerList (position: number) {
    return Http.get('/papi/banner/list', { position })
  },
  /**
   * 患者行为
   * @param rootPathCode 根路径id
   * @param lastPathCode 源页面id
   * @param currentPathCode 当前页面id
   */
  patientBehavior (params: object) {
    return Http.post('/papi/patient/action', params)
  },
  // 添加反馈
  feedbackAdd (params: any) {
    return Http.post('/papi/feedback/add', params)
  },
  /**
   * 意见反馈列表
   */
  feedbackList (params: object) {
    return Http.get('/papi/feedback/list', params)
  }
}
