
import { defineComponent, reactive, toRefs, watch } from 'vue'
// eslint-disable-next-line import/no-named-as-default
import md5 from 'js-md5'
import { Message } from 'view-design'
import { ClinicAPI } from '@/api'

export default defineComponent({
  components: {},
  setup (_props, { emit }) {
    const codeLoginParams = reactive({
      password: '',
      passwordAgain: ''
    })
    const state = reactive({
      times: 3,
      codeErrorMsg: '',
      isSubmit: false,
      step: 1 // 1.重置密码  2.重置成功   3.重置失败
    })
    watch(codeLoginParams, () => {
      if (
        codeLoginParams.password.length > 5 &&
        codeLoginParams.passwordAgain.length > 5
      ) {
        state.isSubmit = true
      }
      else state.isSubmit = false
      state.codeErrorMsg = ''
    })

    const submit = () => {
      if (!state.isSubmit) return
      if (codeLoginParams.password !== codeLoginParams.passwordAgain) {
        return (state.codeErrorMsg = '两次密码不一致，请重新输入')
      }
      // 规则验证
      const passWordReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
      if (!passWordReg.test(codeLoginParams.password)) {
        return (state.codeErrorMsg = '密码格式不正确，请重新输入')
      }
      const params = {
        newPass: md5(codeLoginParams.password),
        userId: sessionStorage.getItem('resetId')
      }
      ClinicAPI.resetPassword(params)
        .then(() => {
          sessionStorage.removeItem('resetId');
          (Message as any).success('成功')
          format()
          countDown()
          state.step = 2
        })
        .catch(() => {
          countDown()
          state.step = 3
        })
    }
    const countDown = () => {
      if (state.times > 0) {
        state.times--
        setTimeout(() => {
          countDown()
        }, 1000)
      } else {
        state.times = 3
        emit('step', 1)
      }
    }
    const format = () => {
      codeLoginParams.password = ''
      codeLoginParams.passwordAgain = ''
    }
    return {
      ...toRefs(state),
      ...toRefs(codeLoginParams),
      submit
    }
  }
})
