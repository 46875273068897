import { RouteConfig } from 'vue-router'

const ClinicRoutes: RouteConfig[] = [
  // 好心情诊所
  {
    path: '/hxqClinic',
    component: () => import('@/views/HxqClinic/index.vue'),
    name: 'hxqClinic',
    meta: {
      isFooterFixed: true,
      footerMarginBottom: false
    }
  },
  // 好心情诊所->医生列表
  {
    path: '/hxqClinic/doctor/list',
    component: () => import('@/views/HxqClinic/DoctorList.vue'),
    name: 'lists',
    meta: {
      isFooterFixed: false,
      footerMarginBottom: false
    }
  },
  // 好心情诊所->医生详情
  {
    path: '/hxqClinic/doctor/detail',
    component: () => import('@/views/HxqClinic/doctorDetail.vue'),
    name: 'detail',
    meta: {
      isFooterFixed: false,
      footerMarginBottom: false,
      needLogin: true
    }
  },
  // 好心情诊所->支付
  {
    path: '/hxqClinic/pay',
    component: () => import('@/views/HxqClinic/pay.vue'),
    name: 'pay',
    meta: {
      isFooterFixed: false,
      footerMarginBottom: true,
      needLogin: true
    }
  },
  {
    path: '/hxqClinic/agreement',
    component: () => import('@/views/HxqClinic/agreement.vue'),
    name: 'Agreement',
    meta: {
      isFooterFixed: false
    }
  },
  {
    path: '/hxqClinic/paySuccess',
    component: () => import('@/views/HxqClinic/PaySuccess.vue'),
    name: 'PaySuccess',
    meta: {
      isFooterFixed: false,
      footerMarginBottom: false
      // needLogin: true
    }
  }
]
export default ClinicRoutes
