var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"header"},[_c('div',{staticClass:"inner clearfix"},[_c('div',{staticClass:"hxq-nav"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.png"),"alt":""}})])],1),_c('div',{staticClass:"hxq-nav-menu-mobile",on:{"click":function($event){_vm.mobileMenuShow = !_vm.mobileMenuShow}}},[_c('span',[_vm._v(_vm._s(_vm.activeName))]),_c('img',{staticClass:"hxq-nav-menu-mobile_icon",attrs:{"src":require("@/assets/mobile/daohang .png"),"alt":""}})]),_c('ul',{staticClass:"hxq-lst hxq-lst-prods clearfix",class:{ show: _vm.mobileMenuShow }},_vm._l((_vm.routeList),function(item,index){return _c('li',{staticClass:"hxq-lst-item",class:{
          active:
            _vm.$route.path === item.path ||
            (_vm.$route.path.indexOf(item.path) === 0 && item.path !== '/'),
        }},[(!item.outerLink)?_c('router-link',{staticClass:"hxq-prod",attrs:{"to":item.path,"tag":"a"},nativeOn:{"click":function($event){_vm.mobileMenuShow = !_vm.mobileMenuShow}}},[_vm._v(_vm._s(item.title))]):_c('a',{staticClass:"hxq-prod",attrs:{"href":item.path,"target":"_blank"}},[_vm._v(_vm._s(item.title))])],1)}),0),_c('div',{staticClass:"hxq-lst-pc"},[_c('div',{staticClass:"login"},[(
            !_vm.$store.getters.getUserInfo || !_vm.$store.getters.getUserInfo.name
          )?_c('div',{staticClass:"login-register"},[_c('span',{class:{ activeBlue: _vm.loginActive === 1 },on:{"click":function($event){return _vm.login(1)}}},[_vm._v("登录")]),_vm._v(" "),_c('span',{staticClass:"line"},[_vm._v("|")]),_vm._v(" "),_c('span',{class:{ activeBlue: _vm.loginActive === 2 },on:{"click":function($event){return _vm.login(2)}}},[_vm._v("注册")])]):_vm._e(),(_vm.$store.getters.getUserInfo && _vm.$store.getters.getUserInfo.name)?_c('div',{staticClass:"login-name",on:{"click":_vm.logout}},[_c('img',{attrs:{"src":require("@/assets/home/touxiang@2x.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$store.getters.getUserInfo.name))])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }