
import { Vue, Component } from 'vue-property-decorator'
// import { Footer } from 'element-ui'
import Feedback from '@/components/feed-back/feedBack.vue'

@Component({
  components: {
    // [Footer.name]: Footer
    Feedback
  }
})
export default class CFooter extends Vue {
  private year = new Date().getFullYear()
  private modalBool = false
  private modalTitle = ''

  protected mounted () {
    console.log(this.$store.state.isFooterFixed)
  }

  private toFeedback () {
    this.modalBool = true
    this.modalTitle = '投诉与建议'
  }

  private changeTitle (title: string) {
    this.modalTitle = title
  }
  private closeModal () {
    this.modalBool = false
    this.modalTitle = ''
  }
}
