
import { defineComponent, reactive, toRefs, watch } from 'vue'
import { isMobilePhone } from '@hxq/utils'
import { ClinicAPI } from '@/api'

export default defineComponent({
  components: {},
  setup (_props, { emit }) {
    const codeLoginParams = reactive({
      mobile: '',
      code: ''
    })
    const state = reactive({
      activeTab: 0,
      codeErrorMsg: '',
      captchaText: '获取验证码',
      isSubmit: false,
      time: 60,
      captchaDisabled: false
    })
    watch(codeLoginParams, () => {
      if (
        codeLoginParams.mobile.length === 11 &&
        codeLoginParams.code.length === 6
      ) {
        state.isSubmit = true
      }
      else state.isSubmit = false
      state.codeErrorMsg = ''
    })
    const getCode = () => {
      // if (process.client) {
      if (state.captchaDisabled || codeLoginParams.mobile.length === 0) return
      if (!isMobilePhone(codeLoginParams.mobile)) {
        return (state.codeErrorMsg = '请输入正确的手机号')
      }
      if (state.captchaDisabled) return
      ClinicAPI.getResetCaptcha(codeLoginParams.mobile).then(() => {
        countDown()
      })
      // }
    }
    const countDown = () => {
      if (state.time > 0) {
        state.time--
        state.captchaText = `${state.time}s后重新获取`
        state.captchaDisabled = true
        setTimeout(() => {
          countDown()
        }, 1000)
      } else {
        state.time = 60
        state.captchaText = '获取验证码'
        state.captchaDisabled = false
      }
    }
    const next = () => {
      if (!state.isSubmit) return
      state.codeErrorMsg = ''
      const params = {
        mobile: codeLoginParams.mobile,
        captcha: codeLoginParams.code
      }
      ClinicAPI.verifyCaptcha(params).then((res: any) => {
        sessionStorage.setItem('resetId', res.result.id)
        format()
        emit('step', 4)
      })
    }
    const format = () => {
      codeLoginParams.mobile = ''
      codeLoginParams.code = ''
    }
    return {
      ...toRefs(state),
      ...toRefs(codeLoginParams),
      next,
      getCode
    }
  }
})
