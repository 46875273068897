
import { Vue, Component, Watch } from 'vue-property-decorator'
import { ClinicAPI } from '@/api'
@Component({
  components: {}
})
export default class HeaderTop extends Vue {
  private mobileMenuShow = false
  private routeList: any[] = [
    {
      path: '/',
      title: '首页'
    },
    {
      path: 'https://mp.hxqhosp.cn/mall',
      title: '好心情严选',
      outerLink: true
    },
    /* {
      path: '/consult',
      title: '我想咨询'
    },
    {
      path: '/doctor',
      title: '我是医生'
    }, */
    {
      path: '/medicineList',
      title: '药品信息'
    },
    {
      path: '/books',
      title: '心理书籍'
    },
    /* {
      path: '/partner',
      title: '合作伙伴'
    }, */
    {
      path: '/about',
      title: '关于我们'
    }
  ]
  private loginActive = 0
  private get activeName () {
    const route = this.routeList.find((item) => {
      return item.path === this.$route.path
    })
    return route ? route.title : ''
  }
  private login (flag: number) {
    this.loginActive = flag
    this.$store.dispatch('setLoginVisible', true)
  }
  private logout () {
    this.$Modal.confirm({
      title: '是否退出登录，退出登录后将返回到首页',
      onOk: () => {
        ClinicAPI.logout().then((res) => {
          this.$store.dispatch('setUserInfo', null)
          if (this.$route.path !== '/') {
            this.$router.push('/')
          }
        })
      }
    })
  }

  // 监听登录状态&登录弹窗开启关闭状态
  @Watch('$store.state.loginVisible', { immediate: true, deep: true })
  private clickActive () {
    if (
      (this.$store.getters.getUserInfo &&
        this.$store.getters.getUserInfo.name) ||
      (!(
        this.$store.getters.getUserInfo && this.$store.getters.getUserInfo.name
      ) &&
        !this.$store.state.loginVisible)
    ) {
      this.loginActive = 0
    }
  }
}
